import Vue from 'vue';
import Router from 'vue-router';

//  移动设备页面路径
import MobileViews from "@/mobileViews/index.vue";  //  主页面

//  电脑页面路径
import Home from '@/views/index.vue';  //  主页面
import Notice from '@/views/notice/index.vue';  //  公告页面

Vue.use(Router);

//  初始化路径参数
var rt = void 0;

//  判断是否为移动端设备
if(/Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(navigator.userAgent)) {
  rt = new Router({
    routes: [
      {
        path: '/',
        name: 'MobileViews',
        component: MobileViews,
        meta:{
          title:'主页面(V0.001内测开发版)'
        }
      },
    ]
  });
}
else{
  const path = require('path');
  const files = require.context('@/views/notice/detail', false, /\.vue$/)
  var noticeList = [];
  files.keys().forEach(key => {
    const name = path.basename(key, '.vue');
    noticeList.push({
      path: '/notice/n' + name,
      name: "Notice" + name,
      component: files(key).default || files(key),
      meta:{
        title: "公告" + name
      }
    });
  });
  
  rt = new Router({
    routes: [
      {
        path: '/',
        name: 'Home',
        component: Home,
        meta:{
          title:'主页面(V0.001内测开发版)'
        }
      },
      {
        path: '/notice',
        name: 'Notice',
        component: Notice,
        meta:{
          title:'主页面(V0.001内测开发版)'
        }
      },
      ...noticeList
    ]
  });
}

export default rt;