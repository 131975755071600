<template>
  <div class="detail">
    <fo-topMenu>
      <template slot="f_content">
        <fo-article
          v-loading="noticeLoading"
          element-loading-text="拼命加载中"
          element-loading-background="rgba(255, 255, 255, 1)"
          :title="noticeDetail.title"
          :createDate="noticeDetail.createDate"
        >
          <!-- 文章内容 -->
          <template v-if="!noticeLoading" slot="content">
            <fo-text fontSize="20px" fontWeight="bold" lineHeight="40px">使用前注意事项：</fo-text>
            <fo-text textIndent>1、因为直播平台基本都有跟QQ音乐合作，没有跟网易云音乐合作，所以点歌机目前只支持从QQ音乐上点歌。</fo-text>
            <fo-text textIndent>2、一部分歌曲即使不登录也能播放。</fo-text>
            <fo-text textIndent>3、QQ音乐本身每过1~2天就会需要重新登录，所以在使用点歌机的点歌功能前，请务必确认当前点歌机上的登录信息是否正常没过期。</fo-text>
            <fo-text textIndent>4、如果想点QQ音乐上的vip歌曲，请确保点歌机保存的QQ音乐登录信息是否拥有绿钻或其它vip权限，不支持试听功能。</fo-text>
            <fo-text textIndent>5、如果QQ音乐的登录信息过期了，请重新在点歌机上设置登录信息。</fo-text>
            <fo-text fontSize="20px" fontWeight="bold" lineHeight="40px" marginTop="15px">操作步骤：</fo-text>
            <fo-text textIndent>1、点击左下方的设置按钮。</fo-text>
            <fo-image :src="imageList[0]" height="auto" fit="contain" note="图1 点击设置按钮"></fo-image>
            <fo-text textIndent marginTop="10px">2、请确保当前点歌机处于登录状态（暂不支持注册，没有账号但想登录使用软件的游客，请联系管理员），点击设置cookie。</fo-text>
            <fo-image :src="imageList[1]" height="auto" fit="contain" note="图2 登录后，点击设置cookie"></fo-image>
            <fo-text textIndent>3、在浏览器内，打开QQ音乐官网网页版，并登录。（<span class="jumpSite" @click.stop="jumpSite">点击跳转官网</span>）</fo-text>
            <fo-text textIndent>4、登录后按F12或手动打开浏览器的开发人员工具，进入到控制台窗口。</fo-text>
            <fo-image :src="imageList[2]" height="auto" fit="contain" note="图3 打开浏览器的控制台窗口"></fo-image>
            <fo-text textIndent>5、复制下方的代码，粘贴到刚刚打开的控制台窗口，按下回车复制登录信息。</fo-text>
            <fo-text textIndent><code style="padding: 2px; background-color: #000; color: #FFF;">try{copy(document.cookie);alert("复制成功");}catch(err){alert("复制报错：" + err)};</code></fo-text>
            <fo-image :src="imageList[3]" height="auto" fit="contain" note="图4 获取登录信息"></fo-image>
            <fo-text textIndent>6、回到点歌机的设置cookie窗口，点击输入框并按下 ctrl + v 的组合键粘贴登录信息。</fo-text>
            <fo-text textIndent>7、粘贴成功后，点击确定按钮校验输入框内的登录信息，设置成功或失败都会有相应的提示。</fo-text>
            <fo-image :src="imageList[4]" height="auto" fit="contain" note="图5 提交登录信息"></fo-image>
            <fo-text fontSize="20px" fontWeight="bold" lineHeight="40px" marginTop="15px">校验cookie是否设置成功的方法：</fo-text>
            <fo-text textIndent>1、提交设置的cookie时，服务端就能校验当前的cookie是否设置成功。（可以直接打开设置cookie窗口，并直接提交已有的数据来校验登录信息是否过期）</fo-text>
            <fo-text textIndent>2、直接在软件点歌界面处，点一首需要正常登录才能点的歌曲（例如：正道的光）。</fo-text>
          </template>
        </fo-article>
      </template>
    </fo-topMenu>
  </div>
</template>
<script>
import foTopMenu from "@/components/foamiceUi/fo-top-menu.vue";
import foArticle from "@/components/foamiceUi/fo-article.vue";
import foText from "@/components/foamiceUi/fo-text.vue";
import foImage from "@/components/foamiceUi/fo-image.vue";
export default {
  components: { foTopMenu, foArticle, foText, foImage },
  data() {
    return {
      //  公告刷新状态
      noticeLoading: true,
      //  公告详情
      noticeDetail: {},
      //  图片列表
      imageList: [
        require("@/static/images/notice/2/1.png"),
        require("@/static/images/notice/2/2.png"),
        require("@/static/images/notice/2/3.png"),
        require("@/static/images/notice/2/4.png"),
        require("@/static/images/notice/2/5.png")
      ]
    };
  },
  created() {
    //  获取公告详情
    this.getNoticeDetail();
  },
  mounted() {},
  methods: {
    //  获取公告详情数据
    getNoticeDetail() {
      var params = this.$route.params;
      if (JSON.stringify(params) != "{}") {
        this.noticeDetail = params;
        this.noticeLoading = false;
      }
      else {
        var path = this.$route.path;
        this.$request({
          url: "https://www.foamice.com/api/notice/list",
          method: "POST",
          data: {
            nId: parseInt(path.substring(9, path.length)),
          },
        })
          .then(({ data }) => {
            let that = this;
            if (data.data) {
              this.noticeDetail = data.data;
              setTimeout(function () {
                that.noticeLoading = false;
              }, 250);
            }
            else {
              this.$notify({
                type: "error",
                title: "错误",
                message: "获取公告失败<br/>2.5s后，自动返回公告页面",
                dangerouslyUseHTMLString: true,
                duration: 2500,
                offset: 50,
              });
              setTimeout(function () {
                that.$router.push({
                  name: "Notice",
                  params: that.$route.params,
                });
              }, 2501);
            }
          })
          .catch((err) => {
            console.log(err);
            this.noticeLoading = false;
          });
      }
    },
    jumpSite(){
      window.open("https://y.qq.com/?ADTAG=myqq#type=index");
    }
  },
};
</script>
<style lang="scss">
.detail {
  height: 100%;
}
.jumpSite{
  color: rgb(94, 122, 36);
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.15s ease;
  user-select: none;
}
.jumpSite:hover{
  color: #409EFF;
}
</style>
