<template>
  <div style="height: 100%">
    <div style="padding: 0 20px 20px 107px; width: calc(100% - 127px)">
      <el-page-header @back="goBackNotice" content="公告页面"></el-page-header>
    </div>
    <div class="fo-article">
      <div
        style="
          margin-bottom: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        "
      >
        <div style="font-size: 28px; font-weight: bold">{{ title }}</div>
        <div
          style="
            margin-top: 7.5px;
            font-size: 14px;
            color: #999aaa;
            letter-spacing: 0.5px;
          "
        >
          [ {{ createDate }} ]
        </div>
      </div>
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: ["createDate", "title"],
  methods: {
    goBackNotice() {
      this.$router.push({
        name: "Notice",
        params: this.$route.params,
      });
    },
  },
};
</script>
<style lang="scss">
.fo-article {
  margin: 0 200px;
  margin-bottom: 40px;
  padding: 20px;
  //  20*2 + 80*2
  width: calc(100% - 540px);
  min-height: calc(100% - 40px);
  background-color: #fff;
}
</style>
