<template>
  <div class="detail">
    <fo-topMenu>
      <template slot="f_content">
        <fo-article
          v-loading="noticeLoading"
          element-loading-text="拼命加载中"
          element-loading-background="rgba(255, 255, 255, 1)"
          :title="noticeDetail.title"
          :createDate="noticeDetail.createDate">
          <!-- 文章内容 -->
          <template v-if="!noticeLoading" slot="content">
            <fo-text textIndent>本站的域名为<span class="webDomainLink" @click.stop="goHome">foamice.com</span>，中文译名：<span style="color: #409EFF;">泡沫冰</span>。</fo-text>
            <fo-text textIndent>本站设有三个原则。</fo-text>
            <fo-text textIndent>第一、绝对不意气用事；</fo-text>
            <fo-text textIndent>第二、绝对不能做任何一件坏事；</fo-text>
            <fo-text textIndent>第三、绝对运营得公正漂亮；</fo-text>
          </template>
        </fo-article>
      </template>
    </fo-topMenu>
  </div>
</template>
<script>
import foTopMenu from "@/components/foamiceUi/fo-top-menu.vue";
import foArticle from "@/components/foamiceUi/fo-article.vue";
import foText from "@/components/foamiceUi/fo-text.vue";
export default {
  components: { foTopMenu, foArticle, foText },
  data() {
    return {
      //  公告刷新状态
      noticeLoading: true,
      //  公告详情
      noticeDetail: {},
    };
  },
  created() {
    //  获取公告详情
    this.getNoticeDetail();
  },
  mounted() {},
  methods: {
    //  获取公告详情数据
    getNoticeDetail() {
      var params = this.$route.params;
      if (JSON.stringify(params) != "{}") {
        this.noticeDetail = params;
        this.noticeLoading = false;
      }
      else {
        var path = this.$route.path;
        this.$request({
          url: "https://www.foamice.com/api/notice/list",
          method: "POST",
          data: {
            nId: parseInt(path.substring(9, path.length))
          },
        })
          .then(({ data }) => {
            let that = this;
            if(data.data){
              this.noticeDetail = data.data;
              setTimeout(function(){
                that.noticeLoading = false;
              }, 250);
            }
            else{
              this.$notify({
                type: "error",
                title: "错误",
                message: "获取公告失败<br/>2.5s后，自动返回公告页面",
                dangerouslyUseHTMLString: true,
                duration: 2500,
                offset: 50,
              });
              setTimeout(function(){
                that.$router.push({
                  name: "Notice",
                  params: that.$route.params
                });
              }, 2501);
            }
          })
          .catch((err) => {
            console.log(err);
            this.noticeLoading = false;
          });
      }
    }
  },
};
</script>
<style lang="scss">
.detail {
  height: 100%;
  //  网站域名链接
  .webDomainLink{
    color: #409EFF;
    cursor: pointer;
  }
  .webDomainLink:hover{
    text-decoration: underline;
  }
}
</style>
