var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fo-text",style:({
    'margin-top': !_vm.marginTop ? '0':_vm.marginTop,
    'text-indent': (_vm.textIndent || _vm.textIndent === '') ? '2em':'0',
    'font-size': !_vm.fontSize ? '16px':_vm.fontSize,
    'font-weight': !_vm.fontWeight ? 'normal':_vm.fontWeight,
    'line-height': !_vm.lineHeight ? '25px':_vm.lineHeight,
    'color': !_vm.color ? '#000000':_vm.color,
  })},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }