<template>
  <div class="mobileViews">
    <div style="padding: 20px; width: calc(100% - 40px); display: flex; align-items: center; flex-direction: column;">
      <i class="el-icon-error" style="margin-top: 50px; font-size: 50px; color: red;"></i>
      <h2 style="font-size: 40px; font-weight: bold; letter-spacing: 0.5px;">暂不支持移动端设备查看该网站哦，亲！</h2>
    </div>
  </div>
</template>
<script>
export default {
  name: "mobileViews",
  data(){
    return{
      
    }
  },
  created(){

  },
  mounted(){

  },
  methods:{

  }
}
</script>
<style lang="scss">
  .mobileViews{
    width: 100%;
    height: 100%;
    color: #333;
  }
</style>
