<template>
  <div
    class="fo-text"
    :style="{
      'margin-top': !marginTop ? '0':marginTop,
      'text-indent': (textIndent || textIndent === '') ? '2em':'0',
      'font-size': !fontSize ? '16px':fontSize,
      'font-weight': !fontWeight ? 'normal':fontWeight,
      'line-height': !lineHeight ? '25px':lineHeight,
      'color': !color ? '#000000':color,
    }">
    <slot></slot>
  </div>
</template>
<script>
  export default {
    props: ["textIndent", "fontSize", "fontWeight", "lineHeight", "color", "marginTop"],
    data(){
     return{
        
     }
    }
  }
</script>
<style lang="scss">
  .fo-text{
    word-break: break-all;
    letter-spacing: 0.5px;
  }
</style>