<template>
  <div class="notice">
    <fo-top-menu>
      <template slot="f_content">
        <div style="padding: 0 20px; width: calc(100% - 40px); height: auto">
          <div
            v-loading="tableLoading"
            element-loading-text="拼命加载中"
            element-loading-background="rgba(255, 255, 255, 1)"
            style="width: 100%; height: 100%"
          >
            <el-table
              key="num"
              :data="noticeList"
              stripe
              :height="tableHeight"
              style="width: 100%"
            >
              <el-table-column show-overflow-tooltip>
                <template slot="header">
                  <div
                    style="
                      width: 100%;
                      height: 100%;
                      font-size: 18px;
                      color: #000;
                    "
                  >
                    公告名称
                  </div>
                </template>
                <template slot-scope="scope">
                  <div style="width: 100%; height: 100%">
                    <span
                      style="text-decoration: underline; cursor: pointer"
                      @click.stop="showDetail(scope.$index)"
                      >{{ scope.row.title }}</span
                    >
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="date"
                width="120"
                show-overflow-tooltip
                align="right"
              >
                <template slot="header">
                  <div
                    style="
                      width: 100%;
                      height: 100%;
                      font-size: 16px;
                      color: #000;
                    "
                  >
                    <!--  -->
                  </div>
                </template>
                <template slot-scope="scope">
                  <div style="width: 100%; height: 100%">
                    [{{ scope.row.createDate.substring(0, 10) }}]
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div
              style="
                padding: 7.5px 0;
                padding-right: 17.5px;
                width: calc(100% - 17.5px);
                height: auto;
                background-color: #fff;
                border: 1px solid #eee;
                border-top-width: 0;
                display: flex;
                justify-content: flex-end;
              "
            >
              <el-pagination
                background
                @size-change="sizeChange"
                @current-change="currentChange"
                :current-page="pages"
                :page-sizes="sizeList"
                :page-size="size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </template>
    </fo-top-menu>
  </div>
</template>
<script>
import foTopMenu from "@/components/foamiceUi/fo-top-menu.vue";
import foArticle from "@/components/foamiceUi/fo-article.vue";
import foText from "@/components/foamiceUi/fo-text.vue";
export default {
  name: "Notice",
  components: { foTopMenu, foArticle, foText },
  data() {
    return {
      //  组合键使用状态（触发为true，没触发为false）
      combinationKeyStatus: false,
      //  导航栏状态
      navPositionStatus: false,
      //  搜索框搜索
      searchText: void 0,
      //  表单随机数
      num: 0,
      //  第几页
      pages: 1,
      //  最大多少条一页
      size: 10,
      //  总共多少条
      total: 0,
      //  选择多少条为一页的列表数据
      sizeList: [10, 15, 20],
      //  头像
      headImg:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      //  表单加载状态
      tableLoading: false,
      //  表单高度
      tableHeight: 500,
      //  表单高度调整循环
      tableHeightlp: void 0,
      //  表单数据
      noticeList: [],
    };
  },
  created() {
    if (JSON.stringify(this.$route.params) != "{}") {
      this.pages = this.$route.params.pages;
      this.size = this.$route.params.size;
    }
  },
  mounted() {
    //  获取公告列表
    this.getNotice();
    var that = this;
    this.tableHeightlp = setInterval(function () {
      //  如果当前路由是这个
      if (that.$route.path === "/notice") {
        that.tableHeight =
          document.getElementsByClassName("foamice_webpage")[0].clientHeight -
          135;
      } else {
        clearInterval(that.tableHeightlp);
        that.tableHeightlp = void 0;
      }
    }, 50);
    document.onkeydown = function (event) {
      var event = event || window.event;
      //  未使用才能触发
      if (!that.combinationKeyStatus) {
        //  如果是 Ctrl+Alt+b
        if (event.ctrlKey && event.altKey && event.keyCode === 66) {
          that.combinationKeyStatus = true;
          that.navPositionStatus = !that.navPositionStatus;
        }
      }
    };
    document.onkeyup = function (event) {
      that.combinationKeyStatus = false;
    };
  },
  methods: {
    //  获取公告列表
    getNotice() {
      this.tableLoading = true;
      this.$request({
        url: "https://www.foamice.com/api/notice/list",
        method: "POST",
        data: {
          pages: this.pages,
          size: this.size,
        },
      })
        .then((res) => {
          this.noticeList = res.data.row;
          this.total = res.data.total;
          this.$nextTick(() => {
            this.upDateTableData();
          });
          let that = this;
          setTimeout(function () {
            that.tableLoading = false;
          }, 250);
        })
        .catch((err) => {
          console.log(err);
          this.tableLoading = false;
          this.$notify({
            type: "error",
            title: "错误",
            message: "获取公告列表失败",
            dangerouslyUseHTMLString: true,
            duration: 2500,
            offset: 50,
          });
        });
    },
    //  获取每页多少条
    sizeChange(val) {
      this.size = val;
      //  改变每页条数，页数归为1
      this.pages = 1;
      this.getNotice();
    },
    //  获取当前是第几页
    currentChange(val) {
      this.pages = val;
      this.getNotice();
    },
    //  更新table数据
    upDateTableData() {
      this.num = Math.random();
    },
    //  查看公告详情
    showDetail(index) {
      let data = this.noticeList[index];
      this.$set(data, "pages", this.pages);
      this.$set(data, "size", this.size);
      this.$router.push({
        name: "Notice" + this.noticeList[index].id,
        params: data,
      });
    },
  },
};
</script>
<style lang="scss">
.notice {
  height: 100%;
}
</style>
