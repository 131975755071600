<template>
  <div class="home">
    <fo-top-menu>
      <template slot="f_content">
        <div
          style="
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
          "
        >
          <el-carousel trigger="click" height="288px" style="width: 1230px">
            <el-carousel-item
              v-for="(item, index) in carouselImgs"
              :key="index"
            >
              <img :src="item" width="100%" height="100%" />
            </el-carousel-item>
          </el-carousel>
          <div
            style="
              margin-top: 25px;
              margin-bottom: 0;
              padding: 20px;
              width: 1190px;
              height: 600px;
              border: 2px solid black;
            "
          >
            内容
          </div>
        </div>
      </template>
    </fo-top-menu>
  </div>
</template>
<script>
import foTopMenu from "@/components/foamiceUi/fo-top-menu.vue";
export default {
  components: { foTopMenu },
  data() {
    return {
      //  组合键使用状态（触发为true，没触发为false）
      combinationKeyStatus: false,
      //  导航栏状态
      navPositionStatus: false,

      //  搜索框搜索
      searchText: void 0,
      //  头像
      headImg:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      //  走马灯图片列表
      carouselImgs: [
        require("@/static/images/home/carousel/1.jpg"),
        require("@/static/images/home/carousel/2.jpg"),
        require("@/static/images/home/carousel/3.jpg"),
        require("@/static/images/home/carousel/4.jpg"),
      ],
    };
  },
  created() {},
  mounted() {
    var that = this;
    document.onkeydown = function (event) {
      var event = event || window.event;
      //  未使用才能触发
      if (!that.combinationKeyStatus) {
        //  如果是 Ctrl+Alt+b
        if (event.ctrlKey && event.altKey && event.keyCode === 66) {
          that.combinationKeyStatus = true;
          that.navPositionStatus = !that.navPositionStatus;
        }
      }
    };
    document.onkeyup = function (event) {
      that.combinationKeyStatus = false;
    };
  },
  methods: {
    //  选择标签
    selectLabel(index) {
      if (index === 5) {
        var data = {
          userId: "123",
        };
        this.$router.push({ name: "Notice", params: data });
      }
    },
  },
};
</script>
<style lang="scss">
//  主页面
.home {
  height: 100%;
}
</style>
