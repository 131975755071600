<template>
  <div class="f_topMenu">
    <div
      id="f_nav"
      :class="navPositionStatus ? 'foamice_nav_hidden' : 'foamice_nav_normal'"
      class="foamice_nav"
    >
      <div class="foamice_nav_flexBox">
        <div class="foamice_logo">FoamIce</div>
        <div class="foamice_label" @click.stop="selectLabel(0)">首页</div>
        <div class="foamice_label" @click.stop="selectLabel(1)">视频</div>
        <div class="foamice_label" @click.stop="selectLabel(2)">资源</div>
        <div class="foamice_label" @click.stop="selectLabel(3)">更多</div>
      </div>
      <div class="foamice_nav_flexBox">
        <div class="foamice_searchBox">
          <el-input
            class="foamice_searchBox_input"
            v-model="searchText"
            type="text"
            size="small"
            placeholder="请输入搜索内容"
            clearable
          ></el-input>
          <el-button
            class="foamice_searchBox_button"
            type="primary"
            @click.stop=""
            >搜 索</el-button
          >
        </div>
      </div>
      <div class="foamice_nav_flexBox">
        <img
          :src="headImg"
          style="margin-right: 5px; border-radius: 50%"
          width="35"
          height="35"
        />
        <div class="foamice_label" @click.stop="selectLabel(4)">足迹</div>
        <div class="foamice_label" @click.stop="selectLabel(5)">公告</div>
        <el-button
          type="primary"
          style="margin-left: 7.5px; padding: 7.5px 20px; border-radius: 15px"
          @click.stop=""
          >按 钮</el-button
        >
      </div>
    </div>
    <div
      :class="
        navPositionStatus ? 'foamice_webpage_hidden' : 'foamice_webpage_normal'
      "
      class="foamice_webpage"
    >
      <slot name="f_content"></slot>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //  组合键使用状态（触发为true，没触发为false）
      combinationKeyStatus: false,
      //  导航栏状态
      navPositionStatus: false,
      //  搜索框搜索
      searchText: void 0,
      //  头像
      headImg:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    };
  },
  mounted() {
    var that = this;
    document.onkeydown = function (event) {
      var event = event || window.event;
      //  未使用才能触发
      if (!that.combinationKeyStatus) {
        //  如果是 Ctrl+Alt+b
        if (event.ctrlKey && event.altKey && event.keyCode === 66) {
          that.combinationKeyStatus = true;
          that.navPositionStatus = !that.navPositionStatus;
        }
      }
    };
    document.onkeyup = function (event) {
      that.combinationKeyStatus = false;
    };
  },
  methods: {
    //  选择标签
    selectLabel(index) {
      //  首页
      if(index === 0){
        this.$router.push({ name: "Home", params: {} });
      }
      //  视频
      else if(index === 1){

      }
      //  资源
      else if(index === 2){

      }
      //  更多
      else if(index === 3){

      }
      //  足迹
      else if(index === 4){

      }
      //  视频
      else if (index === 5) {
        this.$router.push({ name: "Notice", params: {} });
      }
    },
  },
};
</script>
<style lang="scss">
//  顶部菜单栏
.f_topMenu{
  position: relative;
  width: 100%;
  height: 100%;
  color: #333;
  overflow: hidden;
  //  导航栏
  .foamice_nav{
    position: absolute;
    padding: 0 25px;
    width: calc(100% - 50px);
    height: 48px;
    background-color: #FFFFFF;
    border-top: 1px solid rgba(204, 204, 204, 0.5);
    border-bottom: 1px solid rgba(204, 204, 204, 0.5);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: top 0.5s ease;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    z-index: 114514;
    //  导航栏左、中、右侧基础flex布局
    .foamice_nav_flexBox{
      height: 100%;
      display: flex;
      align-items: center;
      //  导航栏logo
      .foamice_logo{
        position: relative;
        margin-right: 10px;
        margin-bottom: 5px;
        font-size: 24px;
        // text-align: center;
        font-weight: bold;
        text-transform: uppercase;
        color: #FFF;
        text-shadow:
        0px 1px 0px #409EFF,
        0px 2px 0px #409EFF,
        0px 3px 0px #409EFF,
        0px 4px 0px rgb(143, 143, 143),  //  最底部
  
        // 0px 1px 0px #C0C0C0,
        // 0px 2px 0px #B0B0B0,
        // 0px 3px 0px #A0A0A0,
        // 0px 4px 0px #909090,
        0px 5px 10px rgba(0, 0, 0, 0.75);
      }
      //  导航栏标签
      .foamice_label{
        margin-left: 5px;
        width: 35px;
        padding: 0 10px;
        height: 100%;
        background-color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center; cursor: pointer;
      }
      //  导航栏标签鼠标移动到上面的样式
      .foamice_label:hover{
        background-color: #F0F0F6;
      }
      //  导航栏搜索盒子样式
      .foamice_searchBox{
        padding: 0 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        //  导航栏搜索框
        .foamice_searchBox_input{
          width: auto;
          //  导航栏搜索框内部样式
          .el-input__inner{
            width: 220px;
            border-radius: 4px 0 0 4px;
            transition: width 0.5s ease;
          }
          //  导航栏搜索框内部样式
          .el-input__inner:focus{
            width: 300px;
          }
        }
        //  导航栏搜索框聚焦样式
        .foamice_searchBox_input:focus{
          width: 300px;
          //  导航栏搜索框内部样式
          .el-input__inner{
            border-radius: 4px 0 0 4px;
          }
        }
        //  导航栏搜索按钮
        .foamice_searchBox_button{
          padding: 0 20px;
          width: 80px;
          height: 32px;
          border-radius: 0 4px 4px 0;
        }
      }
    }
  }
  //  导航栏顶部位置
  .foamice_nav_normal{
    top: 0;
  }
  .foamice_nav_hidden{
    top: -50px;
  }
  //  网页内容
  .foamice_webpage{
    padding-top: 25px;
    width: 100%;
    background-color: #F5F6F7;
    transition-property: margin-top, height;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    overflow-y: auto;
  }
  //  导航栏正常展示，网页位置
  .foamice_webpage_normal{
    margin-top: 50px;
    height: calc(100% - 75px);
  }
  //  导航栏隐藏展示，网页位置
  .foamice_webpage_hidden{
    margin-top: 0;
    height: calc(100% - 25px);
  }
}
</style>
