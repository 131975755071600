<template>
  <div class="fo-image">
    <el-image
      :src="src"
      :fit="!fit ? 'fill' : fit" 
      :style="{
        'width': !width ? '500px':width,
        'height': !height ? '300px':height,
      }">
      <div slot="error" style="width: 100%; height: 100%; background-color: #F5F7FA; font-size: 30px; display: flex; justify-content: center; align-items: center;">
        <i class="el-icon-picture-outline"></i>
      </div>
    </el-image>
    <div
      :style="{
        'margin-top': !noteTop ? '7.5px' : noteTop,
      }"
      style="font-size: 12px; color: #999;">{{ note }}</div>
  </div>
</template>
<script>
  export default {
    data() {
      return {};
    },
    props: ["src", "fit", "note", "width", "height", "noteTop"],
  }
</script>
<style lang="scss">
.fo-image{
  margin: 5px 0;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>